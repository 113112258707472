"use client";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAttributes } from "store/attribute/attribute.actions";
import { fetchAllBrand } from "store/brand/brand.actions";
import { fetchAllCategory } from "store/category/category.actions";

import { getAllColor } from "store/color/color.actions";
import { getAllProducts } from "store/product/product.actions";
import { fetchAllBlog } from "store/blog/blog.actions";
import { fetchAllTickets } from "store/tickets/ticket.actions";
import { fetchAllActivities } from "store/activity/activity.actions";

type DispatcherProps = {
  client?: string;
};

function Dispatcher({ client = "" }: DispatcherProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    const runDispatcher = (client: string) => {
      switch (client) {
        case "categories":
          dispatch(fetchAllCategory());
          break;
        case "brands":
          dispatch(fetchAllBrand());
          break;
        case "attributes":
          dispatch(getAttributes());
          break;
        case "colors":
          dispatch(getAllColor());
          break;
        case "blog":
          dispatch(fetchAllBlog());
          break;
        case "support":
          dispatch(fetchAllTickets());
          break;
        case "products":
          dispatch(getAllColor());
          dispatch(getAllProducts());
          dispatch(fetchAllCategory());
          dispatch(fetchAllBrand());
          dispatch(getAttributes());
          break;
        case "all":
          dispatch(fetchAllCategory());
          dispatch(fetchAllBrand());
          dispatch(getAttributes());
          break;

        default:
          break;
      }
    };

    dispatch(fetchAllActivities());
    runDispatcher(client);
  }, [client, dispatch]);

  return null;
}

export default Dispatcher;
