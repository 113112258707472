import { combineReducers } from "redux";
import dataSlice from "./data/data.slice";
import userSlice from "./user/user.slice";
import categorySlice from "./category/category.slice";
import brandSlice from "./brand/brand.slice";
import attributeSlice from "./attribute/attribute.slice";
import colorSlice from "./color/color.slice";
import productSlice from "./product/product.slice";
import blogSlice from "./blog/blog.slice";
import { themeReducer } from "./theme/theme.reducer";
import supportSlice from "./tickets/ticket.slice";
import activitySlice from "./activity/activity.slice";

export const rootReducer = combineReducers({
  data: dataSlice.reducer,
  user: userSlice.reducer,
  category: categorySlice.reducer,
  brand: brandSlice.reducer,
  attribute: attributeSlice.reducer,
  color: colorSlice.reducer,
  products: productSlice.reducer,
  blog: blogSlice.reducer,
  support: supportSlice.reducer,
  activity: activitySlice.reducer,
  theme: themeReducer,
});
