import { selectAttributeByID } from "store/attribute/attribute.selector";
import { Attribute } from "utils/types/attribute";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Button,
  Selection,
} from "@nextui-org/react";
import { useMemo, useState } from "react";
import { Key } from "@react-types/shared";
import { selectProduct } from "store/product/product.selector";
import { useDispatch, useSelector } from "react-redux";
import { updateProductAttributes } from "store/product/product.actions";

type Props = {
  attribute: Attribute;
  setAttributes: (arr: number[]) => void;
};
function AttributeValue({ attribute, setAttributes }: Props) {
  const [selectedKeys, setSelectedKeys] = useState<Selection>(new Set([0]));
  const product = useSelector(selectProduct);
  const dispatch = useDispatch();
  const values = attribute.values;

  const selectedValue = useMemo(() => {
    if (selectedKeys === "all" || selectedKeys.has("all"))
      return "Select Values . . .";

    const selected = values
      .filter((value: { id: { toString: () => Key } }) =>
        selectedKeys.has(value.id.toString())
      )
      .map((value) => value.value);

    if (!selected || selected.length === 0) return "Select Values . . .";

    return selected.length > 4
      ? `${selected.length} values Selected`
      : selected.join(", ").replaceAll("_", " ");
  }, [selectedKeys, values]);

  const handleSelect = (keys: Selection) => {
      setSelectedKeys(keys);
      const selectedValues = values.filter(
        (value) => keys !== "all" && keys.has(value.id.toString())
      );
      const newAttribute: Attribute = { ...attribute, values: selectedValues };
      dispatch(
        updateProductAttributes({ type: "update", value: newAttribute })
      );
    },
    handleCancel = () => {
      setAttributes(product.attributes.filter((i) => i !== attribute.id));
      dispatch(updateProductAttributes({ type: "delete", value: attribute }));
    },
    options = values.map((value) => ({
      key: value.id,
      label: value.value,
    }));

  return (
    <div>
      <div className="flex items-center gap-3">
        <div className="label">
          <span className="label-text">{attribute.name}</span>
        </div>
        <div className="flex items-center gap-2 w-full">
          <Dropdown>
            <DropdownTrigger>
              <Button
                variant="bordered"
                className="capitalize w-full">
                {selectedValue}
              </Button>
            </DropdownTrigger>
            <DropdownMenu
              aria-label="Multiple selection example"
              variant="flat"
              closeOnSelect={false}
              disallowEmptySelection
              selectionMode={"multiple"}
              selectedKeys={selectedKeys}
              onSelectionChange={handleSelect}
              items={options}>
              {(item) => (
                <DropdownItem key={item.key}>{item.label}</DropdownItem>
              )}
            </DropdownMenu>
          </Dropdown>

          <Button
            isIconOnly
            className="btn-soft-danger hover:!text-white dark:hover:!text-white"
            variant="flat"
            onPress={handleCancel}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AttributeValue;
