import CircleImageBadge from "assets/svgs/circle-image.svg";
import { classNames } from "utils/helper/helper";
import { Button, Spinner } from "@nextui-org/react";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ImCancelCircle } from "react-icons/im";
import { BiCheckCircle } from "react-icons/bi";
import { getEnvProps } from "utils/helper/server-helper";
import { alertMessage } from "../toolkit/initial-state.component";
import { getHeaders } from "utils/header";
import { logger } from "lib/logger";

interface UploadFileProps {
  multiple?: boolean;
  extension?: string;
  dimensions?: {
    width: number;
    height: number;
  };
  ratio?: {
    width: number;
    height: number;
  };
  maxSize?: string; // maxSize in '2mb' or '200kb' format
}

interface FileWithStatus {
  uploadError?: string;
  loading: boolean;
  success?: boolean;
  file: File;
}

const UploadFile: React.FC<UploadFileProps> = ({
  multiple = false,
  extension,
  dimensions,
  ratio,
  maxSize = "2mb",
}) => {
  const [files, setFiles] = useState<FileWithStatus[]>([]);
  const [error, setError] = useState(false);

  const parseSize = (size: string): number => {
    const units = size.slice(-2).toLowerCase();
    const value = parseInt(size.slice(0, -2), 10);
    if (units === "kb") {
      return value * 1024;
    } else if (units === "mb") {
      return value * 1024 * 1024;
    } else {
      throw new Error("Invalid size format. Use 'kb' or 'mb'.");
    }
  };

  const maxSizeBytes = parseSize(maxSize);

  const uploadFile = async (fileWithStatus: FileWithStatus) => {
    const formData = new FormData();
    formData.append("file", fileWithStatus.file);

    const envProps = await getEnvProps();
    const hostURL = envProps.base_url;
    const headers = await getHeaders(undefined, true);

    try {
      const response = await fetch(`${hostURL}/api/v1/file/upload`, {
        method: "POST",
        body: formData,
        headers,
      });

      if (!response.ok) {
        throw new Error("Failed to upload");
      }

      const data = await response.json();
      console.log("File uploaded successfully:", data);

      setFiles((prevFiles) =>
        prevFiles.map((f) =>
          f.file === fileWithStatus.file
            ? { ...f, loading: false, success: true }
            : f
        )
      );
    } catch (error) {
      let errorMessage = "An unknown error occurred.";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      console.error("Error uploading file:", errorMessage);

      setFiles((prevFiles) =>
        prevFiles.map((f) =>
          f.file === fileWithStatus.file
            ? { ...f, uploadError: errorMessage, loading: false }
            : f
        )
      );
    }
  };

  const validateFile = (file: File): Promise<string | null> => {
    return new Promise((resolve) => {
      if (extension && !file.name.endsWith(extension)) {
        resolve(`File extension must be ${extension}`);
        return;
      }

      if (file.size > maxSizeBytes) {
        resolve(`File size must be less than ${maxSize}`);
        return;
      }

      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const { width, height } = img;
        if (
          dimensions &&
          (width !== dimensions.width || height !== dimensions.height)
        ) {
          resolve(
            `Image dimensions must be ${dimensions.width}x${dimensions.height}`
          );
        } else if (ratio && width / height !== ratio.width / ratio.height) {
          resolve(`Image ratio must be ${ratio.width}:${ratio.height}`);
        } else {
          resolve(null);
        }
      };

      img.onerror = () =>
        resolve(`Unable to validate image dimensions or ratio.`);
    });
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setError(false);
      const validateAndProcessFiles = async (files: File[]) => {
        for (const file of files) {
          const validationError = await validateFile(file);
          console.log(validationError);

          if (validationError) {
            setFiles((prevFiles) => [
              ...prevFiles,
              { file, uploadError: validationError, loading: false },
            ]);
          } else {
            const newFileWithStatus: FileWithStatus = {
              file,
              uploadError: undefined,
              loading: true,
            };
            setFiles((prevFiles) => [...prevFiles, newFileWithStatus]);
            uploadFile(newFileWithStatus);
          }
        }
      };

      validateAndProcessFiles(acceptedFiles);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [extension, dimensions, ratio, maxSizeBytes]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: multiple ? 10 : 1,
    onDropRejected(fileRejections) {
      const errorMessage = fileRejections[0].errors[0].message;
      setError(true);
      logger.error("errorMessage: ", errorMessage);

      alertMessage("error", errorMessage);
    },
  });

  return (
    <div className="h-[60vh]">
      <div
        {...getRootProps()}
        className={classNames(
          "px-[12px] py-[24px] relative cursor-pointer h-full",
          "bg-neutralgraygray-25 dark:bg-dark-base/40 rounded-lg overflow-hidden border border-dashed",
          error ? "border-red-500" : "border-colourinputfield"
        )}>
        <input {...getInputProps()} />
        {files.length === 0 &&
          (isDragActive ? (
            <div className="flex flex-col items-center justify-center mt-[20vh] gap-5">
              <CircleImageBadge />
              <p className="font-regular text-neutralgraygray-400">
                Drop the files here...
              </p>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center mt-[20vh] gap-5">
              <CircleImageBadge />
              <p className="font-regular text-neutralgraygray-400">
                Drag {"n"} drop some files here, or click to select files
              </p>

              <Button
                color="secondary"
                variant="bordered">
                Add Image
              </Button>
            </div>
          ))}
        {files.length > 0 && (
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
            {files.map(({ file, uploadError, loading, success }) => (
              <div
                key={file.name}
                className="relative group rounded-lg overflow-hidden h-fit">
                <img
                  src={URL.createObjectURL(file)}
                  alt={file.name}
                  width={200}
                  height={200}
                  className="w-auto h-46"
                />
                {loading && (
                  <div className="absolute rounded-lg inset-0 flex items-center justify-center z-10 bg-white/35 dark:bg-black/35 backdrop-blur-sm">
                    <Spinner />
                  </div>
                )}
                {uploadError != null && (
                  <div className="absolute rounded-lg inset-0 flex flex-col items-center justify-center z-10 bg-white/35 dark:bg-black/35 backdrop-blur-sm">
                    <ImCancelCircle className="text-red-500 h-12 w-12" />
                    <p className="text-red-500 text-center mt-2 hidden group-hover:block">
                      Error: {uploadError}
                    </p>
                  </div>
                )}
                {success != null && success && (
                  <div className="absolute rounded-lg hidden inset-0 group-hover:flex flex-col items-center justify-center z-10 bg-white/35 dark:bg-black/35 backdrop-blur-sm">
                    <BiCheckCircle className="text-green-500 h-12 w-12" />
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadFile;
