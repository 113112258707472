"use client";
import {
  Modal,
  ModalContent,
  ModalBody,
  ModalFooter,
  Button,
  Tabs,
  Tab,
} from "@nextui-org/react";
import { useEffect, useState, useCallback, useMemo } from "react";
import { BiImage, BiUpload } from "react-icons/bi";
import UploadList from "./uploads.component";
import { ImageData, PaginationResponse } from "utils/types/gallery";
import { getEnvProps } from "utils/helper/server-helper";
import UploadFile from "./upload-file.component";
import { alertMessage } from "../toolkit/initial-state.component";
import { getHeaders } from "utils/header";

export enum ExtentionType {
  ANY = "image/any",
  SVG = "svg",
  PNG = "png",
  JPG = "jpg",
  JPEG = "jpeg",
}

type UploaderProps = {
  extension?: ExtentionType;
  isOpen: boolean;
  onOpenChange: () => void;
  multiple?: boolean;
  dimensions?: {
    width: number;
    height: number;
  };
  ratio?: {
    width: number;
    height: number;
  };
  handleChange: (value: ImageData[]) => void;
  files: ImageData[] | undefined;
  maxSize?: string; // maxSize in '2mb' or '200kb' format
};

function Uploader({
  isOpen,
  onOpenChange,
  extension = ExtentionType.ANY,
  multiple = false,
  ratio,
  dimensions,
  handleChange,
  files,
  maxSize,
}: UploaderProps) {
  const [selected, setSelected] = useState<ImageData[]>([]);
  const [uploads, setUploads] = useState<PaginationResponse<ImageData> | null>(
      null
    ),
    [loading, setLoading] = useState(false);
  const uploadExtensions =
    extension === ExtentionType.ANY ? undefined : extension;

  const getUploads = useCallback(async (link: string, paginate = false) => {
      const envProps = await getEnvProps();
      const hostURL = envProps.base_url;
      const apiKey = envProps.apiKey;

      if (apiKey == null) return;
      const uri = paginate ? `${hostURL}${link}` : link;
      setLoading(true);
      try {
        const data = await fetch(uri, {
          headers: await getHeaders(),
        });
        const res = await data.json();
        setUploads(res);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }, []),
    prevFunc = () => {
      if (uploads == null) return;
      if (uploads.prev_page_url != null) getUploads(uploads.prev_page_url);
    },
    NextFunc = () => {
      if (uploads == null) return;
      if (uploads.next_page_url != null) getUploads(uploads.next_page_url);
    },
    addFiles = ({ hideModal = () => {} }) => {
      if (selected.length === 0) {
        alertMessage("warn", "Please select images");
        return;
      }

      // Check for ratio and extension
      for (const file of selected) {
        if (extension !== ExtentionType.ANY && file.extension !== extension) {
          alertMessage(
            "warn",
            `The file ${file.filename} does not match the required extension.`
          );
          return;
        }
        if (ratio && file.width && file.height) {
          const fileRatio = file.width / file.height;
          const requiredRatio = ratio.width / ratio.height;
          if (fileRatio !== requiredRatio) {
            alertMessage(
              "warn",
              `The file ${file.filename} does not match the required ratio.`
            );
            return;
          }
        }
        if (dimensions && file.width && file.height) {
          const isSameWidth = file.width === dimensions.width;
          const isSameHeight = file.height === dimensions.height;
          if (isSameWidth && isSameHeight) {
            alertMessage(
              "warn",
              `The file ${file.filename} does not match the required dimensions.`
            );
            return;
          }
        }
      }

      handleChange(selected);
      hideModal();
    };

  const handleSelect = (value: ImageData) => {
    setSelected((prev) => {
      if (prev.some((item) => item.id === value.id)) {
        return prev.filter((item) => item.id !== value.id);
      }
      if (multiple) {
        if (prev.length >= 10) {
          alertMessage("warn", "You can only select up to 10 files.");
          return prev;
        }
        return [...prev, value];
      } else {
        return [value];
      }
    });
  };

  useEffect(() => {
    if (isOpen) {
      const link = "/api/v1/user/gallery";
      const url =
        extension !== ExtentionType.ANY
          ? `${link}?extension=${extension}`
          : link;
      getUploads(url, true);
    }
  }, [extension, getUploads, isOpen]);
  useEffect(() => {
    if (files != null) {
      setSelected(files);
    }
  }, [files]);
  const tabsContent = useMemo(
    () => (
      <Tabs
        aria-label="Options"
        color="secondary"
        variant="bordered">
        <Tab
          key="files"
          title={
            <div
              className="flex items-center gap-2"
              onClick={() => getUploads("/api/v1/user/gallery", true)}>
              <BiImage />
              <span>Select File</span>
            </div>
          }>
          <UploadList
            selected={selected}
            handleSelect={handleSelect}
            uploads={uploads?.data || null}
          />
        </Tab>
        <Tab
          key="upload"
          title={
            <div className="flex items-center gap-2">
              <BiUpload />
              <span>Upload File</span>
            </div>
          }>
          <UploadFile
            multiple={multiple}
            ratio={ratio}
            dimensions={dimensions}
            extension={uploadExtensions}
            maxSize={maxSize}
          />
        </Tab>
      </Tabs>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected, uploads?.data, multiple, ratio, dimensions, uploadExtensions]
  );

  return (
    <Modal
      size={"5xl"}
      isOpen={isOpen}
      onOpenChange={onOpenChange}>
      <ModalContent>
        {(onClose) => (
          <>
            <ModalBody>
              <div className="flex w-full flex-col">{tabsContent}</div>
            </ModalBody>
            <ModalFooter className="justify-between">
              <div className="flex items-center">
                <Button
                  color="danger"
                  variant="light"
                  size="sm"
                  onPress={() => setSelected([])}>
                  Clear
                </Button>
                <div className="flex gap-2">
                  <Button
                    size="sm"
                    variant="flat"
                    className="btn-soft-primary"
                    onPress={prevFunc}
                    isLoading={loading}
                    isDisabled={
                      uploads == null ? false : uploads.prev_page_url == null
                    }>
                    Previous
                  </Button>
                  <Button
                    size="sm"
                    variant="flat"
                    className="btn-soft-primary"
                    onPress={NextFunc}
                    isLoading={loading}
                    isDisabled={
                      uploads == null ? false : uploads.next_page_url == null
                    }>
                    Next
                  </Button>
                </div>
              </div>
              <Button
                className="btn-soft-success"
                size="sm"
                onPress={() => addFiles({ hideModal: onClose })}>
                Add File{multiple ? "s" : ""}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}

export default Uploader;
